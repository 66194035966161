import React from 'react'
import './bg-purple.jpg'
import './danno.jpg'
import { ReactComponent as TwitterIcon } from './social-twitter.svg'
import { ReactComponent as InstagramIcon } from './social-instagram.svg'
import { ReactComponent as YouTubeIcon } from './social-youtube.svg'
import { ReactComponent as TwitchIcon } from './social-twitch.svg'
import './App.css'

function App() {
  return (
    <div className="app">
      <div className="bg">
        <div className="card">
          <div className="photo" />
          <h1>DannielleSarah</h1>
          <p className="bio">
            {'School teacher who runs purely on coffee. AH/RT '}
            <span role="img" aria-label="green heart">
              💚
            </span>
            <span role="img" aria-label="black heart">
              🖤
            </span>
            {' Crossfit'}
            <span role="img" aria-label="muscle flex">
              💪
            </span>
            <span role="img" aria-label="weight lifter">
              🏋
            </span>
            ️ {'Dancer '}
            <span role="img" aria-label="dancer">
              💃
            </span>
            {' Gamer '}
            <span role="img" aria-label="video game controller">
              🎮
            </span>
            {' @YouTube & @Twitch'}
          </p>
          <a className="btn" href="mailto:danniellesarah+web@gmail.com">
            Get in touch
          </a>
          <ul>
            <li className="social">
              <a
                href="https://twitter.com/danniellesarah"
                aria-label="View my Twitter profile"
              >
                <TwitterIcon className="icon" />
              </a>
            </li>
            <li className="social">
              <a
                href="https://www.instagram.com/idreaminpixels"
                aria-label="Follow me on Instagram"
              >
                <InstagramIcon className="icon" />
              </a>
            </li>
            <li className="social">
              <a
                href="https://m.twitch.tv/danniellesarah/profile"
                aria-label="Watch me on Twitch"
              >
                <TwitchIcon className="icon" />
              </a>
            </li>
            <li className="social">
              <a
                href="https://www.youtube.com/user/DannielleSarah"
                aria-label="Subscribe on YouTube"
              >
                <YouTubeIcon className="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default App
